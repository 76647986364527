import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { TypeAnimation } from "react-type-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import devimg from "../images/dev.jpg";
import uximg from "../images/ux-design.webp";
import softdevimg from "../images/software-development.webp";
import softtestimg from "../images/software-testing.webp";
import devopsimg from "../images/devops.webp";
import AppHeader from './layouts/AppHeader';
import FooterNav from './FooterNav';
import StackNav from './StackNav';


const Services = (props) => {

  return (
    <>
      <div id="codekali_wrapper">
                 <AppHeader/>
  
        <div className="container">
          <div className="card codekali-round bg-black">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
               
                  <TypeAnimation
                    className="text-white codekali-bold codekali-typing mb-3"
                    sequence={[
                      "Explore our services",
                      3000,
                      "<Explore our services/>",
                      3000,
                     
                      
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ display: "inline-block" }}
                    repeat={Infinity}
                  />
              
    <p className="text-white">
                   We are a software consulting firm that specializes in enterprise business
                    applications that often host large amounts of data. 
                   Our experience has a rich history of creating both web and 
                   desktop-based solutions. We focus on building cutting edge applications 
                   that utilize the latest web technologies.
                    </p>

    <p className="text-white">
                    One of our favorite parts of the process is working with customers to provide a 
                    high level of customization without sacrificing our strong commitment to quality. 
                    From CRMs to lead generation platforms, 
                    our custom-made software frequently becomes the sturdy pillar of our clients’ operation.
                    </p>

                              <a
                    className="btn btn-outline-info btn-lg w-100 text-white codekali-round codekali-bold"
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/254700422699?text=Hello%20Codekali%20Developers,%20I%20would%20like%20to%20hire%20your%20software%20development%20services%20in%20relation%20to%20the%20following:"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} /> Get in Touch
                  </a>
                </div>
                <div className="col-md-6 mb-3">
              

                             <ProgressiveImage src={devimg} placeholder={devimg}>
            {(src, loading) => (
              <img
                className={`image${
                  loading ? " loading w-100 codekali-round mb-3" : " loaded w-100 codekali-round mb-3"
                }`}
                src={src}
                alt="About Codekali"
                width="200"
                height="75"
              />
            )}
          </ProgressiveImage>
        
                </div>
              </div>
            </div>
          </div>
          <div className="py-5"></div>

          <div className="text-center">
            <TypeAnimation
              className="text-white codekali-bold mb-3"
              sequence={[
                "Codekali Services",
                3000,
                "<Codekali Services/>",
                3000,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
            />
          </div>

        


            <div className="row mb-5">

            <div className="col-md-6 mb-3">
            <div className="card h-100 codekali-round bg-black">
            <div className="card-body">
           

            <div className="row">

            <div className="col-md-6 text-center">
   <img
              src={uximg}
              alt="UX Codekali"
              className="class-fluid"
            />
            </div>

                 <div className="col-md-6">

          <h3 className="text-warning codekali-bold">UX & UI DESIGN</h3>
    <p className="text-white">

    We design user friendly and intuitive interfaces expected by today’s consumer using the latest UX and UI best practices.

                    </p>
 
            </div>

            </div>

           


            </div>
            </div>
            </div>




                 <div className="col-md-6 mb-3">
            <div className="card h-100 codekali-round bg-black">
            <div className="card-body">

                  <div className="row">

            <div className="col-md-6 text-center">
              <img
              src={softdevimg}
              alt="Software Dev Codekali"
              className="class-fluid"
            />

            </div>

            <div className="col-md-6">
          <h3 className="text-warning codekali-bold">SOFTWARE DEVELOPMENT</h3>

  <p className="text-white">

We transform your vision into a modern application using the best technologies for the solution and the latest standards and best practices.
                    </p>
            </div>




            </div>

            </div>
            </div>
            </div>

                         <div className="col-md-6 mb-3">
            <div className="card h-100 codekali-round bg-black">
            <div className="card-body">
               <div className="row">

            <div className="col-md-6 text-center">

              <img
              src={softtestimg}
              alt="Software Test Codekali"
              className="class-fluid"
            />
            </div>

     <div className="col-md-6">
          <h3 className="text-warning codekali-bold">SOFTWARE QA & TESTING</h3>
<p className="text-white">
We deliver high quality software using automated tests and thorough QA testing.
    </p>
            </div>

   </div>

            </div>
            </div>
            </div>

                           <div className="col-md-6 mb-3">
            <div className="card h-100 codekali-round bg-black">
            <div className="card-body">

               <div className="row">

            <div className="col-md-6 text-center">
      <img
              src={devopsimg}
              alt="Devops Codekali"
              className="class-fluid"
            />
            </div>

     <div className="col-md-6">
          <h3 className="text-warning codekali-bold">DEVOPS</h3>

 <p className="text-white">
           Accelerate software delivery using automated builds, continuous integration, and release automation.

    </p>
            </div>

   </div>

        
            </div>
            </div>
            </div>

       

            </div>



     <StackNav/>
<FooterNav/>


           
        </div>
      </div>
    </>
  );
};

export default Services;
