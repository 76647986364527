import React from "react";
import { useState } from "react";
import { faBarChart } from "@fortawesome/free-solid-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user-info"));

  // console.log(user['user']['name'])

  function logOut() {
    localStorage.clear();

    navigate("/login");
  }
 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return ( 
    <>
      <div className="header-area shadow-sm">
        <div className="container-fluid py-1">
          <div className="header-content header-style-app position-relative d-flex align-items-center justify-content-between">
            <Link className="text-site" to={`/dashboard`}>
              Codekali
            </Link>
            {localStorage.getItem("user-info") ? (
              <>
                <Link className="text-site" to={"/dashboard"}>
                  <FontAwesomeIcon icon={faBarChart} />
                </Link>
              </>
            ) : (
              <>
                <Link className="text-site" to={"/register"}>
                  <FontAwesomeIcon icon={faUserPlus} />
                </Link>
              </>
            )}

            <Button variant="info" className="text-white" onClick={handleShow}>
              <FontAwesomeIcon icon={faBarsStaggered} />
            </Button>
          </div>
        </div>
      </div>
  <div className="py-4">
         </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="codekali-sidecats-menu px-0">
            {localStorage.getItem("user-info") ? (
              <>
                 <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/dashboard`}
                  >
                <li className="mb-4">
                    Admin Dashboard
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>

                     <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/categories`}
                  >
                <li className="mb-4">
                    Categories
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>


                     <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/subcategories`}
                  >
                <li className="mb-4">
                    SubCategories
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>


                     <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/users`}
                  >
                <li className="mb-4">
                    Users
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>




                     <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/projects/list`}
                  >
                <li className="mb-4">
                    Projects
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>

                          <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/project/create`}
                  >
                <li className="mb-4">
                    Create Project
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>

                            <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/category/create`}
                  >
                <li className="mb-4">
                    Create Category
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>

                         <Link
                    onClick={handleClose}
                    className="text-dark"
                    to={`/subcategory/create`}
                  >
                <li className="mb-4">
                    Create Subcategory
                    <FontAwesomeIcon
                      className="float-end"
                      icon={faAngleRight}
                    />
                </li>
                </Link>
              </>
            ) : (
              <>
                <Link
                  onClick={handleClose}
                  className="btn btn-outline-warning w-100 mb-3"
                  to={"/login"}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Login
                </Link>
                <Link
                  onClick={handleClose}
                  className="btn btn-primary w-100 mb-3"
                  to={"/register"}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Create Account
                </Link>
              </>
            )}
            <li className="mb-4">
              <Link onClick={handleClose} className="text-dark" to={`/`}>
                Home
                <FontAwesomeIcon
                  className="float-end"
                  icon={faAngleRight}
                />
              </Link>
            </li>

            {localStorage.getItem("user-info") ? (
              <>
                <Button
                  title={
                    user && user.user.name ? user.user.name : "Codekali User"
                  }
                  className="btn btn-primary w-100"
                  onClick={logOut}
                >
                  <FontAwesomeIcon icon={faSignOut} /> Sign Out
                </Button>
              </>
            ) : (
              <></>
            )}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
