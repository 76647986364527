import React, { useEffect,useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from "react-router-dom";


const Users = () => {

  const [search, setSearch] = useState("");
const [users,setUsers] = useState([]);
const [isLoading, setIsLoading] = useState(true);
 
// fetch users data from fetch method || variable users

const fetchData = () =>{
return fetch("https://api.codekali.com/api/users")
.then((response) => response.json())
.then((data) => setUsers(data['users']))
 .finally(() => {
  setIsLoading(false);
 });
}
  const handleSubmit = (e) => {
    e.preventDefault();
  };

useEffect(() => {

setIsLoading(true);
fetchData();

},[])

	return(
   <>
      <Header/>
      <div className="container-fluid">
            
                <section className="search-bar">
        <div className="text-center">
        <p>
        <small>Filter by name</small>
        </p>
        </div>
          <form
            className=" d-flex justify-content-center col-md-6 mx-auto my-2"
            onSubmit={handleSubmit}
          >
            <input
              className="form-control shadow-sm text-center"
              type="search"
              placeholder="Search..."
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </section>

        <section className="py-3 px-2">
        <div className="table-responsive">
          <table className="table table-striped table-bordered w-100 shadow-sm">
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {  !isLoading ? (users&&users.length>0 && users.filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.name.toLowerCase().includes(search);
                })
 
                .map((user, index) => {
                  return (

                     <>
                    <tr key={index}>
                      <td>{user.name}</td>
                      <td>{user.phone}</td>
                      <td>{user.email}</td>
                      <td>{user.created_at}</td>
                      <td>
                      <div>
                      <Link
                          to={`#`}
                          className="btn btn-primary w-50 mb-2 btn-sm"
                        >
                          View
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={`#`}
                          className="btn btn-warning btn-sm"
                        >
                          Update
                        </Link>
                      </div>
                      </td>
                    </tr>
                     </>
                  );
                })) :  Array(20)
               .fill()
               .map((item, index) => (

                         <tr key={index}>
                      <td><Skeleton height={25} width={`80%`} /></td>
                      <td><Skeleton height={25} width={`80%`} /></td>
                      <td><Skeleton height={25} width={`80%`} /></td>
                      <td><Skeleton height={25} width={`100%`} /></td>
                      <td>
                     <Skeleton height={30} width={`50%`} />
                      <Skeleton height={30} width={`50%`} />
                      </td>
                    </tr>
            
               )) 


             }
            </tbody>
          </table>
          </div>
        </section>
           
      </div>
      <Footer/>
   </>
	)
}

export default Users