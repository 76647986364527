import React from "react";
import { useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import mongodbimg from "../images/mongodb.png";
import expressimg from "../images/express.png";
import reactimg from "../images/react.png";
import nodejsimg from "../images/nodejs.png";
import bootstrapimg from "../images/bootstrap.png";
import pythonimg from "../images/python.png";
import mysqlimg from "../images/mysql.png";
import laravelimg from "../images/laravel.png";
import jsimg from "../images/js.png";
import rocketimg from "../images/rocket.webp";
import tailwindimg from "../images/tailwind.png";
import postgresqlimg from "../images/postgresql.png";
import about1img from "../images/about1.jpg";
import AppHeader from "./layouts/AppHeader";


const Contact = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <div id="codekali_wrapper">
        <AppHeader />
        <div className="text-center pt-5">
          <img id="rocket" src={rocketimg} alt="rocket" />
        </div>
        <div className="container">
          <div className="card codekali-round bg-black">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TypeAnimation
                    className="text-white codekali-bold mb-3"
                    sequence={[
                      "About Codekali",
                      3000,
                      "<Discover more about us.../>",
                      3000,
                      "Our vision & mission",
                      3000,
                      "Our pledge plus dedication!",
                      3000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ fontSize: "2em", display: "inline-block" }}
                    repeat={Infinity}
                  />
                  <p className="text-white">
                    Codekali was founded back in 2016 as a software development
                    firm.
                  </p>

                  <p className="text-white mb-3">
                    We specialise in full stack and mobile development,
                    providing services to local and international companies.
                  </p>

                  <p className="text-white">
                    Our clients come to us seeking customized, skillfully
                    crafted business applications. Our longevity is due to the
                    skill and efficiency of our team of senior software
                    engineers, project managers, and devops staff.
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <img
                    alt=""
                    src={about1img}
                    className="img-fluid w-100 codekali-round mb-3"
                  />
                  <a
                    className="btn btn-outline-info btn-lg w-100 text-white codekali-round codekali-bold"
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/254700422699?text=Hello%20Codekali%20Developers,%20I%20would%20like%20to%20hire%20your%20software%20development%20services%20in%20relation%20to%20the%20following:"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} /> Get in Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5"></div>

          <div className="text-center">
            <TypeAnimation
              className="text-white codekali-bold mb-3"
              sequence={["Codekali Vision", 3000, "<Codekali Vision/>", 3000]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
            />
          </div>

          <div className="card mb-5 codekali-round bg-black">
            <div className="card-body">
              <h3 className="text-warning codekali-bold">Our Vision</h3>
              <p className="text-white">
                Our vision is to position and consolidate our company as a
                leading supplier of Software Engineering products and services
                at a national level. With international projection,
                methodologies and standards, we use cutting-edge technologies
                that allow us to develop effective, reliable, scalable, and
                economic products that improve our costumers’ competitiveness
                and our own as well.
              </p>
            </div>
          </div>

          <div className="text-center">
            <TypeAnimation
              className="text-white codekali-bold mb-3"
              sequence={[
                "Codekali Mission",
                3000,
                "< Codekali Mission/>",
                3000,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
            />
          </div>

          <div className="card mb-5 codekali-round bg-black">
            <div className="card-body">
              <h3 className="text-warning codekali-bold">Our Mission</h3>
              <p className="text-white">
                Our commitment is to assist our clients to achieve a more
                competitive and profitable situation, applying the principles of
                organization, engineering, methodology and human resources. We
                develop and apply technologically innovating solutions to
                satisfy the customer's needs and goals.
              </p>
            </div>
          </div>

          <div className="text-center">
            <TypeAnimation
              className="text-white codekali-bold mb-3"
              sequence={[
                "Codekali Core Values",
                3000,
                "< Codekali Core Values/>",
                3000,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: "2em", display: "inline-block" }}
              repeat={Infinity}
            />
          </div>

          <div className="card mb-5 codekali-round bg-black">
            <div className="card-body">
              <h3 className="text-warning codekali-bold">Our Core Values</h3>
              <p className="text-white">
                We base our actions on the following principles:
              </p>
              <ul className="text-white">
                <li>Honesty and Respect.</li>
                <li>Quality.</li>
                <li>Efficiency.</li>
                <li>Innovation and Investigation.</li>
                <li>Team work.</li>
                <li>Commitment.</li>
              </ul>
            </div>
          </div>

          <h3 className="codekali-bold text-center text-white mb-3">
            Codekali Stack & Technologies
          </h3>

          <div className="row justify-content-center mb-3">
            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={mongodbimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={expressimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={reactimg}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={nodejsimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={bootstrapimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={laravelimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={pythonimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={jsimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={mysqlimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt="Tailwind"
                      className="img-fluid client-img carmel-round w-100"
                      src={tailwindimg}
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt="Postgresql"
                      className="img-fluid client-img carmel-round w-100"
                      src={postgresqlimg}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
