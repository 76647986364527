import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';
// import '../assets/admin/css/style.css';
 

const Dashboard = () => {

return(
<>
<Header/>
<div className="container-fluid">
 
     <div className="row mb-3">
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/subcategory/create`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faPlusCircle} /> Create SubCategory</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/category/create`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faPlusCircle} /> Create Category</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/project/create`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faPlus} /> Create Project</small>
               </div>
            </div>
         </Link>
      </div>
                <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/projects/list`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faList} /> All Projects</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/projects`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faLaptop} /> Website Projects</small>
               </div>
            </div>
         </Link>
      </div>
  
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/users`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faUsers} /> All Users</small>
               </div>
            </div>
         </Link>
      </div>
            <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/categories`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faList} /> All Categories</small>
               </div>
            </div>
         </Link>
      </div>

          <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={`/subcategories`}>
            <div className="card soko-round">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faList} /> All SubCategories</small>
               </div>
            </div>
         </Link>
      </div>

   </div> 
</div>
<Footer/>
</>
)

}

export default Dashboard
