import React from "react";
import { TypeAnimation } from "react-type-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faBuildingCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import ProgressiveImage from "react-progressive-graceful-image";
import about3img from "../images/devabout.jpg";
import AppHeader from "./layouts/AppHeader";
import FooterNav from "./FooterNav";
import StackNav from "./StackNav";

const About = (props) => {
  return (
    <>
      <div id="codekali_wrapper">
        <AppHeader />

        <div className="container">
          <div className="card codekali-round mb-3 bg-black">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TypeAnimation
                    className="text-white codekali-bold codekali-typing mb-3"
                    sequence={[
                      "About Codekali",
                      3000,
                      "<Discover more about us.../>",
                      3000,
                      "Our vision & mission",
                      3000,
                      "Our pledge plus dedication!",
                      3000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{ display: "inline-block" }}
                    repeat={Infinity}
                  />
                  <p className="text-white">
                    Codekali was founded back in 2016 as a software development
                    firm.
                  </p>

                  <p className="text-white mb-3">
                    We specialise in full stack and mobile development,
                    providing services to local and international companies.
                  </p>

                  <p className="text-white">
                    Our clients come to us seeking customized, skillfully
                    crafted business applications. Our longevity is due to the
                    skill and efficiency of our team of senior software
                    engineers, project managers, and devops staff.
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                  <ProgressiveImage src={about3img} placeholder={about3img}>
                    {(src, loading) => (
                      <img
                        className={`image${
                          loading
                            ? " loading w-100 codekali-round mb-3"
                            : " loaded w-100 codekali-round mb-3"
                        }`}
                        src={src}
                        alt="About Codekali"
                        width="200"
                        height="75"
                      />
                    )}
                  </ProgressiveImage>
                  <a
                    className="btn btn-outline-info btn-lg w-100 text-white codekali-round codekali-bold"
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/254700422699?text=Hello%20Codekali%20Developers,%20I%20would%20like%20to%20hire%20your%20software%20development%20services%20in%20relation%20to%20the%20following:"
                  >
                    
                    <FontAwesomeIcon icon={faWhatsapp} /> Get in Touch
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card h-100 codekali-round bg-black">
                <div className="card-body">
                  <h3 className="text-warning codekali-title codekali-bold">
                    <FontAwesomeIcon icon={faLightbulb} /> Vision
                  </h3>
                  <p className="text-white">
                    Our vision is to position and consolidate our company as a
                    leading supplier of Software Engineering products and
                    services at a national level.
                  </p>
                  <p className="text-white">
                    With international projection, methodologies and standards,
                    we use cutting-edge technologies that allow us to develop
                    effective, reliable, scalable, and economic products that
                    improve our costumers’ competitiveness and our own as well.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card h-100 codekali-round bg-black">
                <div className="card-body">
                  <h3 className="text-warning codekali-title codekali-bold">
                   <FontAwesomeIcon icon={faStarOfLife} /> Mission
                  </h3>
                  <p className="text-white">
                    Our commitment is to assist our clients to achieve a more
                    competitive and profitable situation, applying the
                    principles of organization, engineering, methodology and
                    human resources.
                  </p>
                  <p className="text-white">
                    We develop and apply technologically innovating solutions to
                    satisfy the customer's needs and goals.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            <TypeAnimation
              className="text-white codekali-bold codekali-typing mb-3"
              sequence={[
                "Codekali Core Values",
                3000,
                "< Codekali Core Values/>",
                3000,
              ]}
              wrapper="span"
              speed={50}
              style={{ display: "inline-block" }}
              repeat={Infinity}
            />
          </div>

          <div className="card mb-5 codekali-round bg-black">
            <div className="card-body">
              <h3 className="text-warning codekali-title codekali-bold">
                <FontAwesomeIcon icon={faBuildingCircleCheck} /> Core Values
              </h3>
              <p className="text-white">
                We base our actions on the following principles:
              </p>
              <ol className="text-white">
                <li>Honesty and Respect.</li>
                <li>Quality.</li>
                <li>Efficiency.</li>
                <li>Innovation and Investigation.</li>
                <li>Team work.</li>
                <li>Commitment.</li>
              </ol>
            </div>
          </div>

          <StackNav />

          <FooterNav />
        </div>
      </div>
    </>
  );
};

export default About;
