import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProjectsNav = () => {
  const [products, setProducts] = useState([]);
  const [ongoing_projects, setOngoing] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjects = () => {
    return fetch("https://api.codekali.com/api/getproducts")
      .then((response) => response.json())
      .then((data) => setProducts(data["projects"]))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchOngoing = () => {
    return fetch("https://api.codekali.com/api/getproducts")
      .then((response) => response.json())
      .then((data) => setOngoing(data["ongoing_projects"]))
      .finally(() => {
        setIsLoading(false);
      });
  };

 
  useEffect(() => {
    setIsLoading(true);
    fetchProjects();
    fetchOngoing();
    // setIsLoading(false);
  }, []);
  return (
    <>
      <div className="text-center">
        <h3 className="codekali-bold text-center codekali-title text-white mb-3">
          Recent Projects
        </h3>
      </div>

      <div className="row justify-content-center mb-5">
        {!isLoading
          ? products &&
            products.length > 0 &&
            products.map((product) => {
              return (
                <>
                  <div key={product.id} className="col-md-3 mb-3">
                    <Link to={`${product.link}`} target={"_blank"}>
                      <div className="card codekali-popup-card bg-dark">
                        <div className="card-body">
                          <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <img
                              alt={product.name}
                              className="img-fluid client-img w-100"
                              src={`https://api.codekali.com/product/image/${product.image}`}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              );
            })
          : Array(12)
              .fill()
              .map((item, index) => (
                <div key={index} className="col-md-3 mb-3">
                  <div className="card codekali-popup-card bg-dark">
                    <div className="card-body">
                      <Skeleton
                        baseColor="#202020"
                        highlightColor="#444"
                        height={150}
                        width={`100%`}
                      />
                    </div>
                  </div>
                </div>
              ))}
      </div>
     

      <div className="text-center">
        <h3 className="codekali-bold text-center codekali-title text-white mb-3">
          Ongoing Projects
        </h3>
      </div>


           <div className="row justify-content-center mb-5">
        {!isLoading
          ? ongoing_projects &&
            ongoing_projects.length > 0 &&
            ongoing_projects.map((ongoing_project) => {
              return (
                <>
                  <div key={ongoing_project.id} className="col-md-3 mb-3">
                    <Link to={`${ongoing_project.link}`} target={"_blank"}>
                      <div className="card codekali-popup-card bg-dark">
                        <div className="card-body">
                          <div className="card-img-overlay d-flex justify-content-center align-items-center">
                            <img
                              alt={ongoing_project.name}
                              className="img-fluid client-img w-100"
                              src={`https://api.codekali.com/product/image/${ongoing_project.image}`}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              );
            })
          : Array(12)
              .fill()
              .map((item, index) => (
                <div key={index} className="col-md-3 mb-3">
                  <div className="card codekali-popup-card bg-dark">
                    <div className="card-body">
                      <Skeleton
                        baseColor="#202020"
                        highlightColor="#444"
                        height={150}
                        width={`100%`}
                      />
                    </div>
                  </div>
                </div>
              ))}
      </div>
 
    </>
  );
};

export default ProjectsNav;
