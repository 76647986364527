import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

const Products = () => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    return fetch("https://api.codekali.com/api/getproducts")
      .then((response) => response.json())
      .then((data) => setProducts(data["all_projects"]))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // console.log(products);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // setIsLoading(false);
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <section className="search-bar">
          <div className="text-center">
            <p>
              <small>Filter by name</small>
            </p>
          </div>
          <form
            className=" d-flex justify-content-center col-md-6 mx-auto my-2"
            onSubmit={handleSubmit}
          >
            <input
              className="form-control shadow-sm text-center"
              type="search"
              placeholder="Search..."
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </section>

        <section className="py-3 px-2">
          <div className="table-responsive">
            <table className="table table-striped table-bordered w-100 shadow-sm">
              <thead className="table-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scopr="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading
                  ? products &&
                    products.length > 0 &&
                    products
                      .filter((item) => {
                        return search.toLowerCase() === ""
                          ? item
                          : item.name.toLowerCase().includes(search);
                      })

                      // .filter(user => user.age > 40 && user.occupation === 'programmer');

                      .map((product, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{product.name}</td>
                              <td>
                                <Link
                                  to={`#`}
                                  className="btn btn-primary me-2 btn-sm"
                                >
                                  View
                                </Link>
                                <Link
                                  to={`#`}
                                  className="btn btn-warning btn-sm"
                                >
                                  Update
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })
                  : Array(20)
                      .fill()
                      .map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <Skeleton height={25} width={`80%`} />
                          </th>
                          <td>
                            <Skeleton height={25} width={`80%`} />
                          </td>
                          <td>
                            <Skeleton height={30} width={`20%`} />
                            <Skeleton height={30} width={`20%`} />
                          </td>
                        </tr>
                      ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Products;
