import React from "react";
import AppHeader from "./layouts/AppHeader";
import ProjectsNav from "./ProjectsNav";
import StackNav from "./StackNav";

import FooterNav from "./FooterNav";

const Projects = (props) => {

  return (
    <>
      <div id="codekali_wrapper">
        <AppHeader />

        <div className="container">
          <ProjectsNav />

          <StackNav />
          <FooterNav />
        </div>
      </div>
    </>
  );
};

export default Projects;
