import './App.css';
import './assets/admin/css/style.css';
import {Routes, Route} from 'react-router-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Register from './components/Register';
import Login from './components/Login';
import Protected from './components/Protected';
import Users from './components/Users';
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';
import ScrollToTop from "./components/ScrollToTop";
import Categories from './components/Categories';
import AddCategory from './components/AddCategory';
import AddSubCategory from './components/AddSubCategory';
import SubCategories from './components/SubCategories';
import AddProject from './components/AddProject';

import Products from './components/Products';
import Profile from './components/Profile';

import EditProduct from "./components/EditProduct";

import EditCategory from "./components/EditCategory";
import Messages from './components/Messages';

import EditSubCategory from "./components/EditSubCategory";


function App() {

return(
<Router>
       <div className="app-wrapper">
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/dashboard" element={
                <Protected Cmp={Dashboard}/>} />
                <Route path="/about" element={<About/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/projects" element={<Projects/>}/>
                <Route path="/projects/list" element={<Products/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/users" element={
              <Protected Cmp={Users}/>} />
              <Route path="/categories" element={<Categories/>}/>
                <Route path="/subcategories" element={<SubCategories/>}/>
                   <Route path="/messages" element={
              <Protected Cmp={Messages}/>} />
           
                <Route path="/product/edit/:id" element={
              <Protected Cmp={EditProduct}/>} />
                <Route path="/category/edit/:id" element={
              <Protected Cmp={EditCategory}/>} />
               <Route path="/subcategory/edit/:id" element={
              <Protected Cmp={EditSubCategory}/>} />
            <Route path="/category/create" element={
              <Protected Cmp={AddCategory}/>} />
               <Route path="/subcategory/create" element={
              <Protected Cmp={AddSubCategory}/>} />
  <Route exact path="/profile" element={
              <Protected Cmp={Profile}/>} />
                  <Route exact path="/project/create" element={
              <Protected Cmp={AddProject}/>} />
            </Routes>
            <div className="py-3"></div>
            <ScrollToTop />
       </div>
  
</Router>
);
}

export default App;
