import React from 'react';
import logoimg from "../images/logo.png";
import rocketimg from "../images/rocket.webp";

const FooterNav = () => {

    return(
<>
      
       <div className="text-center pt-5">
          <img id="rocket" src={rocketimg} alt="rocket" />
        </div>

                  <div className="text-center mt-3">
                    <img
                      src={logoimg}
                      alt="Codekali Logo"
                      className="class-fluid mb-3"
                      width="240"
                      height="90"
                    />
                  </div>


 

 


 </>

 )
}


export default FooterNav
