import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import { Link } from "react-router-dom";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Login() {
  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/dashboard", { replace: true });
    }
  });

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error_list, setErrors] = useState([]);
  const [validationError, setValidationError] = useState({});
  const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);
  const [type, setType] = useState("password");
  const handleToggle = () => {
    setIsChecked(!isChecked);
    // alert(type);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };


  async function signIn() {
    // console.warn( username, password)

    let item = { username, password };

    // console.warn(item)

    let result = await fetch("https://api.codekali.com/api/login", {
      method: "POST",
      body: JSON.stringify(item),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    result = await result.json();

    if (result.status === 200) {
      localStorage.setItem("user-info", JSON.stringify(result));

      navigate("/dashboard", { replace: true });
    } else {
      // navigate("/login")

      // return;

      // alert(result);
 

        console.log(result['msg']);

      if (result.errors) {

      let error_list;

      error_list = result.errors;

      // alert(error_list);

      setErrors(error_list);
     console.log(result.errors);

      }else{

      let error_list;
      error_list = result.msg;
      // alert(error_list);

      // setErrors(error_list);
     console.log(error_list);

          setValidationError(error_list);

      }


    }
  }

  return (
    <>
      <Header />
      <div className="container-fluid">

        <div className="col-md-6 offset-md-3">
          <div className="card codekali-rounded shadow-sm mb-3">
            <div className="card-body">
              <h4 className="text-center codekali-bold mb-3">Codekali Login</h4>
                    {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="p-0">
                        {Object.entries(validationError).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <form id="signUpVendor" onSubmit={(e) => {e.preventDefault();}}>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="Username">
                    <Form.Label>Username</Form.Label>
                    <input
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                      type="text"
                      placeholder="Phone/Email"
                      autoComplete="new-username"
                      className="form-control"
                    />

                    <span className="text-danger">{error_list.username}</span>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="mb-3">
                  <Form.Group controlId="Password">
                    <Form.Label>Password</Form.Label>

                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      type={type}
                      placeholder="Password"
                      autoComplete="new-password"
                      className="form-control"
                    />

                    <span className="text-danger">{error_list.password}</span>
                  </Form.Group>
                </Col>
              </Row>

                    <div className="mb-3">
            
               <Form.Check  
        type="switch"
        id="togglePwd"
        className="codekali-pointer"
        label="Show Password"
             checked={isChecked}
                onChange={handleToggle}
      />
              </div>

              <button
                type="submit"
                onClick={signIn}
                className="btn btn-primary w-100 mb-3"
              >
               <FontAwesomeIcon icon={faSignIn} /> Login
              </button>
              </form>
            <div className="text-center mb-3">
              <Link to={"/register"}>
                New here? Create Account
              </Link>
            </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
