import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { TypeAnimation } from "react-type-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import nboimg from "../images/nbo.jpg";
import AppHeader from './layouts/AppHeader';
import ProjectsNav from './ProjectsNav';
import StackNav from './StackNav';
import FooterNav from './FooterNav';

const Home = (props) => {

  return (
    <>
      <div id="codekali_wrapper">
                 <AppHeader/>


        <div className="container">
          <div className="card codekali-round mb-3 bg-black">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                 
         
                  <TypeAnimation
                    className="text-white codekali-bold codekali-typing mb-3"
                    sequence={[
                      "Karibu Codekali",
                      3000,
                      "<We are full stack developers/>",
                      3000,
                      "Based in Nairobi, Kenya",
                      3000,
                      "We develop web applications,",
                      3000,
                      "Native and Hybrid Mobile Apps",
                      3000,
                      "Full Stack Development...",
                      3000,
                      "We also do payment integrations,",
                      3000,
                      "Software & System Development.",
                      3000,
                      "We do API Development too...",
                      3000,
                      "Software design & architecture,",
                      3000,
                      "We do API & Integration...",
                      3000,
                      "Cross-Platform Development,",
                      3000,
                      "DevOps and Continuous Integration",
                      3000,
                      "We just get things done.",
                      3000,
                      "Having a project or idea in mind?",
                      3000,
                      "Let us get started now...",
                      3000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{  display: "inline-block" }}
                    repeat={Infinity}
                  />
                  <p className="text-white">
                    Codekali is a one stop tech point for top software
                    developers, engineers, and consultants.
                  </p>
                  <p className="text-white">
                    Top companies and start-ups choose Codekali software
                    developers for their mission critical software projects.
                  </p>
                  <p className="text-white">
                    Having a project or an idea in mind?{" "}
                    <a
                      className="text-warning"
                      target="_blank"
                      rel="noreferrer"
                      href="https://wa.me/254700422699?text=Hello%20Codekali%20Developers,%20I%20would%20like%20to%20hire%20your%20software%20development%20services%20in%20relation%20to%20the%20following:"
                    >
                      Get Started Now!
                    </a>
                  </p>
                </div>
                <div className="col-md-6 mb-3">
                     <ProgressiveImage
                      src={nboimg}
                      placeholder={nboimg}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${
                            loading
                              ? " loading img-fluid codekali-round mb-3 w-100"
                              : " loaded img-fluid codekali-round mb-3 w-100"
                          }`}
                          src={src}
                          alt="home1"
                        />
                      )}
                    </ProgressiveImage>
                    
                  <a
                    className="btn btn-outline-info btn-lg w-100 text-white codekali-round codekali-bold"
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/254700422699?text=Hello%20Codekali%20Developers,%20I%20would%20like%20to%20hire%20your%20software%20development%20services%20in%20relation%20to%20the%20following:"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} /> Get in Touch
                  </a>
                </div>
              </div>
            </div>
          </div>

<ProjectsNav/>
<StackNav/>

        

          <FooterNav/>

        </div>
      </div>
    </>
  );
};

export default Home;
