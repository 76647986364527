import React from 'react';
import { TypeAnimation } from "react-type-animation";
import mongodbimg from "../images/mongodb.png";
import expressimg from "../images/express.png";
import reactimg from "../images/react.png";
import nodejsimg from "../images/nodejs.png";
import bootstrapimg from "../images/bootstrap.png";
import pythonimg from "../images/python.png";
import mysqlimg from "../images/mysql.png";
import laravelimg from "../images/laravel.png";
import jsimg from "../images/js.png";
import tailwindimg from "../images/tailwind.png";
import postgresqlimg from "../images/postgresql.png";

const StackNav = () => {

    return(
<>
 

          <div className="text-center">
                <TypeAnimation
              className="text-white codekali-bold codekali-typing mb-3"
              sequence={[
                "Codekali Stack & Technologies",
                3000,
                "< Codekali Stack & Technologies/>",
                3000,
              ]}
              wrapper="span"
              speed={50}
              style={{  display: "inline-block" }}
              repeat={Infinity}
            />
                  </div>

          <div className="row justify-content-center mb-3">
            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={mongodbimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={expressimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={reactimg}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={nodejsimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={bootstrapimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={laravelimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={pythonimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={jsimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt=""
                      className="img-fluid client-img carmel-round w-100"
                      src={mysqlimg}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt="Tailwind"
                      className="img-fluid client-img carmel-round w-100"
                      src={tailwindimg}
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="card codekali-popup-card bg-white">
                <div className="card-body">
                  <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <img
                      alt="Postgresql"
                      className="img-fluid client-img carmel-round w-100"
                      src={postgresqlimg}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>


 </>

 )
}


export default StackNav
