import React, {Component} from 'react';
import { faHomeAlt } from "@fortawesome/free-solid-svg-icons";

import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';


export default class Footer extends Component{


	render(){

		return(
      <>

         <div className="py-5">
         </div>

	   <div className="footer-nav-area shadow-lg">
         <div className="container-fluid h-100">
            <div className="footer-nav h-100">
               <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
                  <li>
                     <Link className="text-site codekali-footer-icon" to={`/dashboard`}><FontAwesomeIcon icon={faHomeAlt} /></Link>
                  </li>
                
                   <li>
                     <Link className="text-site codekali-footer-icon text-primary" to={`/project/create`}><FontAwesomeIcon className="fs-1" icon={faPlusCircle} /></Link>
                  </li>
                  {

                  localStorage.getItem('user-info') ?
                  <>
               
                   <li>
                     <Link className="text-site codekali-footer-icon" to={`/profile`}><FontAwesomeIcon className="fs-2" icon={faUserCircle} /></Link>
                  </li>
                  </>
                  :

                  <>
                  <li>
                     <Link className="text-site codekali-footer-icon" to={`/register`}><FontAwesomeIcon icon={faUserPlus} /></Link>
                  </li>

                    <li>
                     <Link className="text-site codekali-footer-icon" to={`/login`}><FontAwesomeIcon icon={faSignIn} /></Link>
                  </li>
                  </>

                  }
              
                 
                
               </ul>
            </div>
         </div>
      </div>
      </>
			)
	}
}