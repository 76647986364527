import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';

const Profile = () => {
	return(
    <>
      <Header/>
      <div className="container-fluid">
         <div className="py-3"></div>
      
      <div className="card shadow-sm h-100">
        <div className="card-body">

        <h6>My Profile</h6>

        </div>
      </div>

      </div>

      <Footer/>
   </>

	)
}

export default Profile