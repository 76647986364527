import React from 'react';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';

const Categories = () => {

const [categories, setCats] = useState([]);
const [isLoading, setIsLoading] = useState(true);

const fetchData = () =>{
return fetch("https://api.codekali.com/api/categories")
// return fetch("https://api.codekali.com/api/getcategories")
.then((response) => response.json())
.then((data) => setCats(data['categories']))
 .finally(() => {
   // console.log(data);
  setIsLoading(false);
 });
}
 
useEffect(() => {
setIsLoading(true);
fetchData();
// setIsLoading(false);
},[])
 
const categoriesCount = categories.length;

return(
   <>
      <Header/>
      <div className="container-fluid">
         <div className="py-3"></div>
         <h5 style={{ marginBottom: "1.5rem" }}> {categoriesCount<1 ? 'Loading...' : 'Manage Categories('+categoriesCount+')'} </h5>
         <div className="user-container">
            <div className="row">
               { !isLoading ? (categories&&categories.length>0 && categories.map(categoriesObj => (
               <div key={categoriesObj.id} className="col-md-3 mb-3">
                  <div className="card codekali-round shadow-sm h-100">
                     <div className="card-body">
                        <img loading="lazy" className="img-fluid" width="50" height="50" 
                        src={categoriesObj.image ? `https://api.codekali.com/category/image/${categoriesObj.image}` : `https://codekali.com/app/images/avatar.png`} 
                        alt="{categoriesObj.name}" />
                        <h4><small>{categoriesObj.name}</small></h4>
                        <p> {categoriesObj.description ?   categoriesObj.description  : `New Product`} </p>
                      <div className="row mb-3">
                      <div className="col-md-6">
                        <Link to={`/category/edit/${categoriesObj.id}`} className='btn btn-primary w-100'>
                        <FontAwesomeIcon icon={faEdit} />
                        Edit
                        </Link>
                     </div>
                      <div className="col-md-6">
                        <Link className="btn btn-outline-warning w-100" to={`/category/create`}>
                        <FontAwesomeIcon icon={faPlus} />
                        Add New</Link>
                     </div>

                     </div>
                    
                  </div>
               </div>
               </div>
               ))) :  Array(50)
               .fill()
               .map((item, index) => (
               <div className="col-md-3 mb-3" key={index}>
                  <div className="card codekali-round shadow-sm">
                     <div className="card-body">
                        <h4 className="card-title">
                           <Skeleton circle={true} height={50} width={50} />
                           <Skeleton height={25} width={`80%`} />
                        </h4>
                        <p className="card-channel">
                           <Skeleton width={`60%`} />
                        </p>
                           <div className="row">
                           <div className="col-md-6 mb-3">
                              <div className="card-metrics">
                           <Skeleton height={30} width={`100%`} />
                        </div>
                           </div>
                            <div className="col-md-6 mb-3">
                              <div className="card-metrics">
                           <Skeleton height={30} width={`100%`} />
                        </div>
                           </div>
                            
                        </div>
                     </div>
                  </div>
               </div>
               )) 
               }
            </div>
         </div>
      </div>
      <Footer/>
   </>

)
}

export default Categories
