import React from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";
import aboutimg from "../../images/about.png";
import servicesimg from "../../images/services.png";
import projectsimg from "../../images/projects.png";
import homeimg from "../../images/home.png";
import logoimg from "../../images/logo.png";

const AppHeader = () => {
 

  return (
    <>
      <div className="d-flex codekali-app-header bg-black mb-3 flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <Link
          to={"/"}
          className="my-lg-0 me-lg-auto text-white text-decoration-none"
        >

          <ProgressiveImage src={logoimg} placeholder={logoimg}>
            {(src, loading) => (
              <img
                className={`image${
                  loading ? " loading img-fluid header-nav-logo" : " loaded img-fluid header-nav-logo"
                }`}
                src={src}
                alt="Codekali Logo"
                width="200"
                height="75"
              />
            )}
          </ProgressiveImage>
      
        </Link>
        <ul className="nav col-12 col-lg-auto justify-content-between my-md-0 text-small">
           <li>
            <Link to={"/"} className="nav-link">
         
       <ProgressiveImage src={homeimg} placeholder={homeimg}>
                {(src, loading) => (
                  <img
                    className={`image${
                      loading
                        ? " loading img-fluid header-nav-icon"
                        : " loaded img-fluid header-nav-icon"
                    }`}
                    src={src}
                    alt="Codekali Home"
                  />
                )}
              </ProgressiveImage>
            </Link>
          </li>

          <li>
            <Link to={"/about"} className="nav-link">
                   <ProgressiveImage src={aboutimg} placeholder={aboutimg}>
                {(src, loading) => (
                  <img
                    className={`image${
                      loading
                        ? " loading img-fluid header-nav-icon"
                        : " loaded img-fluid header-nav-icon"
                    }`}
                    src={src}
                    alt="About Codekali"
                  />
                )}
              </ProgressiveImage>
            </Link>
          </li>

          <li>
            <Link to={"/services"} className="nav-link">
                   <ProgressiveImage src={servicesimg} placeholder={servicesimg}>
                {(src, loading) => (
                  <img
                    className={`image${
                      loading
                        ? " loading img-fluid header-nav-icon"
                        : " loaded img-fluid header-nav-icon"
                    }`}
                    src={src}
                    alt="Codekali Services"
                  />
                )}
              </ProgressiveImage>
            </Link>
          </li>

          <li>
            <Link to={"/projects"} className="nav-link">
                 <ProgressiveImage src={projectsimg} placeholder={projectsimg}>
                {(src, loading) => (
                  <img
                    className={`image${
                      loading
                        ? " loading img-fluid header-nav-icon"
                        : " loaded img-fluid header-nav-icon"
                    }`}
                    src={src}
                    alt="Codekali Projects"
                  />
                )}
              </ProgressiveImage>
            </Link>
          </li>
 
        </ul>
      </div>
    </>
  );
};

export default AppHeader;
